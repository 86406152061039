import {Injectable} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {SubsPlan} from '../subs-plan/subs-plan.model';
import {QueryFilter} from '../filters/query-filter.model';
import {RenewalPeriodEnum} from '../../shared/enums/renewal-period.enum';

@Injectable()
export class NavigationService {
  constructor(
    private router: Router
  ) {}

  navigateToLogin() {
    this.router.navigate(['/signin']);
  }

  navigateToLoginChangePassword(email: string) {
    this.router.navigate(['/login/change-password/' + email]);
  }

  navigateToLoginForgotPassword(email = '') {
    if (email.length) {
      this.router.navigate(['/login/forgot-password/' + email]);
    } else {
      this.router.navigate(['/login/forgot-password']);
    }
  }

  navigateToLoginResetPassword(email: string) {
    this.router.navigate(['/login/reset-password/' + email]);
  }

  navigateToMedicDashboard() {
    this.router.navigate(['/medic/dashboard']);
  }

  navigateToMedicPatient(patientId: number) {
    this.router.navigate(['/medic/patients/' + patientId + '/dashboard']);
  }

  navigateToMedicPatients(patientSearch?: string) {
    const navigationExtras: NavigationExtras = {};
    if (patientSearch && patientSearch.length > 0) {
      navigationExtras.queryParams = {search: patientSearch};
    }

    this.router.navigate(['/medic/patients'], navigationExtras);
  }

  navigateToPatientDashboard() {
    this.router.navigate(['/dashboard']);
  }

  navigateToPatientOnboardingFinished() {
    this.router.navigate(['/onboarding/finished']);
  }

  navigateToPatientOnboardingHealth() {
    this.router.navigate(['/onboarding/health']);
  }

  navigateToPatientOnboardingMultiFactorAuthentication() {
    this.router.navigate(['/onboarding/mfa']);
  }

  navigateToPatientOnboardingPayment(subsPlan: SubsPlan, renewalPeriod: RenewalPeriodEnum) {
    this.router.navigate(['/onboarding/payment/' + subsPlan.code + '/' + renewalPeriod]);
  }

  navigateToPatientOnboardingPaymentSuccess() {
    this.router.navigate(['/onboarding/payment-success']);
  }

  navigateToPatientOnboardingStart() {
    this.router.navigate(['/onboarding/start']);
  }

  navigateToPatientOnboardingSubscription() {
    this.router.navigate(['/onboarding/subscription']);
  }

  navigateToRegistrationConfirmation(email: string) {
    this.router.navigate(['/registration/confirmation/' + email]);
  }

  navigateToRegistrationConfirmationInvalid(email: string) {
    this.router.navigate(['/registration/confirmation-invalid/' + email]);
  }

  navigateToRegistrationConfirmationSent(email: string) {
    this.router.navigate(['/registration/confirmation-sent/' + email]);
  }

  navigateToRegistrationStart() {
    this.router.navigate(['/registration/start']);
  }

  navigateToRegistrationSuccess() {
    this.router.navigate(['/registration/success']);
  }

  navigateToPatientSettingsSubscription() {
    this.router.navigate(['/patient/settings/subscription']);
  }
}
