import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {StripeCardComponent} from '../../../shared/payment/stripe/stripe-card/stripe-card.component';
import {PaymentMethodEnum} from '../../../shared/enums/payment-method.enum';
import {RenewalPeriodEnum} from '../../../shared/enums/renewal-period.enum';
import {SubsPlan} from '../../../core/subs-plan/subs-plan.model';
import {hasValue} from '../../../../lib/validator';
import {Subscription} from '../../../core/subscription/subscription.model';
import {SubscriptionService} from '../../../core/subscription/subscription.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslatedToastrService} from '../../../core/translated-toastr/translated-toastr.service';

@Component({
  selector: 'pat-patient-settings-payment',
  templateUrl: './patient-settings-payment.component.html',
  styleUrls: ['./patient-settings-payment.component.scss']
})
export class PatientSettingsPaymentComponent implements OnInit {
  @Input() subsPlan: SubsPlan;
  @Input() renewalPeriod: RenewalPeriodEnum;
  @ViewChild('stripeCard') stripeCard: StripeCardComponent;
  isInProgress = false;
  isLoaded = false;
  isCardCompleted = false;
  paymentMethod = PaymentMethodEnum.STRIPE;
  urlConditions = 'https://medakte.com/terms/';
  urlPrivacy = 'https://medakte.com/privacy-policy/';

  RenewalPeriodEnum = RenewalPeriodEnum;

  constructor(
    private activeModal: NgbActiveModal,
    private subscriptionService: SubscriptionService,
    private translatedToastrService: TranslatedToastrService,
  ) { }

  ngOnInit() {
    if (!!this.subsPlan) {
      this.isLoaded = true;
    }
  }

  isValid() {
    return hasValue(this.subsPlan) &&
      hasValue(this.subsPlan.code) &&
      hasValue(this.renewalPeriod) &&
      hasValue(this.paymentMethod);
  }

  onClickCancel() {
    this.closeActiveModal();
  }

  onClickClose() {
    this.closeActiveModal();
  }

  onClickConfirm() {
    this.saveSubscriptionAndPayment();
  }

  onStripeCardCompleted(isStripeCardCompleted: boolean) {
    this.isCardCompleted = isStripeCardCompleted;
  }

  private closeActiveModal(isSaved = false) {
    this.activeModal.close(isSaved);
  }

  private async saveSubscriptionAndPayment() {
    // Stripe (Yearly)
    if (this.isCardCompleted && !this.isInProgress && this.isValid()) {
      this.isInProgress = true;

      const subscription = new Subscription();
      subscription.planCode = this.subsPlan.code;
      subscription.renewalPeriod = this.renewalPeriod;
      subscription.paymentMethod = this.paymentMethod;

      const source = await this.stripeCard.obtainSource();

      if (source) {
        this.subscriptionService.save(subscription)
          .subscribe(resp => {
                if (resp.result.approvalUrl) {
                  // @ts-ignore
                  window.location = resp.result.approvalUrl;
                } else {
                  this.isInProgress = false;
                  this.translatedToastrService.success('message.success.payment.stripe');
                  this.closeActiveModal(true);
                }
            },
            () => {
              this.isInProgress = false;
              this.translatedToastrService.error('message.error.payment.stripe');
            });
      } else {
        this.translatedToastrService.error('message.error.payment.creditCard');
        this.isInProgress = false;
      }
    } else {
      this.translatedToastrService.error('message.error.payment.creditCard');
      this.isInProgress = false;
    }
  }
}
