import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import * as moment from 'moment-timezone';
import {TranslateService} from '@ngx-translate/core';
import {LocaleResolverService} from '../../../core/locale/locale-resolver.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-user-language-menu',
  templateUrl: './user-language-menu.component.html',
  styleUrls: ['./user-language-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserLanguageMenuComponent implements OnDestroy, OnInit {
  languageLocaleList = ['de-AT', 'en-GB'];
  locale: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private localeResolverService: LocaleResolverService,
    private ref: ChangeDetectorRef,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.localeResolverService.localeSubject.subscribe(() => {
        if (this.locale !== this.localeResolverService.obtainLocale()) {
          this.locale = this.localeResolverService.obtainLocale();
          this.ref.markForCheck();
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onClickLanguageLocale(languageLocale: string) {
    this.locale = languageLocale;
    this.translateService.use(languageLocale);
    moment.locale(languageLocale);
    this.localeResolverService.setLocale(languageLocale);
    this.ref.markForCheck();
  }
}
