import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {HttpClient, HttpClientXsrfModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LOCATION_INITIALIZED, registerLocaleData} from '@angular/common';
import localeDeAt from '@angular/common/locales/de-AT';
import localeEnGb from '@angular/common/locales/en-GB';
import localeRu from '@angular/common/locales/ru';

import {FlatpickrModule} from 'angularx-flatpickr';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {TreeModule} from 'angular-tree-component';
import {QuillModule} from 'ngx-quill';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ToastrModule} from 'ngx-toastr';
import {ResponsiveModule} from 'ngx-responsive';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {SideMenuComponent} from './side-menu/side-menu.component';
import {OnboardingProfileComponent} from './onboarding/profile/onboarding-profile.component';
import {OnboardingProfileModalComponent} from './onboarding/profile/onboarding-profile-modal.component';
import {OnboardingCompletionComponent} from './onboarding/completion/onboarding-completion.component';
import {HomeComponent} from './home/home.component';
import {GoogleComponent} from './social/google/google.component';
import {GoogleCallbackComponent} from './social/google/google-callback/google-callback.component';
import {SharedModule} from './+modules/shared/shared.module';
import {OnboardingStartComponent} from './onboarding/onboarding-start/onboarding-start.component';
import {ChatComponent} from './chat/chat.component';
import {InboxComponent} from './inbox/inbox.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {PrivacyEnComponent} from './privacy/privacy-en/privacy-en.component';
import {PrivacyDeComponent} from './privacy/privacy-de/privacy-de.component';
import {PrivacyRuComponent} from './privacy/privacy-ru/privacy-ru.component';
import {TermsComponent} from './terms/terms.component';
import {TermsDeComponent} from './terms/terms-de/terms-de.component';
import {TermsEnComponent} from './terms/terms-en/terms-en.component';
import {TermsRuComponent} from './terms/terms-ru/terms-ru.component';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
import {PersonTypeSelectionComponent} from './onboarding/person-type-selection/person-type-selection.component';
import {AppConfig} from './app-config';
import {CoreModule} from './+modules/core/core.module';
import {PatientModule} from './+modules/patient/patient.module';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json?v=1');
}

export function appInitializerFactory(
    translateService: TranslateService,
    injector: Injector
) {
    return () =>
        new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(
                LOCATION_INITIALIZED,
                Promise.resolve(null)
            );
            locationInitialized.then(() => {
                // register locals
                registerLocaleData(localeDeAt);
                registerLocaleData(localeEnGb);
                registerLocaleData(localeRu);

                // init translations
                const defaultLang = 'en-GB';
                translateService.addLangs(['de-AT', 'en-GB']);
                translateService.setDefaultLang(defaultLang);

                let language = defaultLang;
                const cookieLanguage = localStorage.getItem('locale');
                const browserLanguage = translateService.getBrowserLang();

                // get language either from cookie or browser if set
                if (cookieLanguage && cookieLanguage.match(/de-AT|en-GB/)) {
                    language = cookieLanguage;
                } else if (browserLanguage && browserLanguage.match(/de-AT|en-GB/)) {
                    language = browserLanguage;
                }

                translateService
                    .use(language)
                    .subscribe(
                        () => {
                        },
                        () => {
                            console.error(
                                `Problem with '${language}' language initialization.'`
                            );
                        },
                        () => {
                            resolve(null);
                        }
                    );
            });
        });
}

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: 'localhost'
    },
    palette: {
        popup: {
            background: '#ffffff',
            text: '#878ca2',
            link: '#878ca2'
        },
        button: {
            background: '#878ca2',
            text: '#ffffff',
            border: 'transparent'
        }
    },
    theme: 'edgeless',
    type: 'info',
    content: {
        href: 'https://medakte.com/privacy-policy/'
    }
};

@NgModule({
  imports: [
    // Angular Modules
    BrowserAnimationsModule,
    BrowserModule,
    // Medakte Modules
    AppRoutingModule,
    CoreModule,
    SharedModule,
    PatientModule,
    // 3rd Party Modules
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    FlatpickrModule.forRoot(),
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    QuillModule,
    ResponsiveModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TreeModule.forRoot()
  ],
  declarations: [
    AppComponent,
    ChatComponent,
    GoogleCallbackComponent,
    GoogleComponent,
    HomeComponent,
    InboxComponent,
    OnboardingCompletionComponent,
    OnboardingProfileComponent,
    OnboardingProfileModalComponent,
    OnboardingStartComponent,
    PersonTypeSelectionComponent,
    PrivacyComponent,
    PrivacyDeComponent,
    PrivacyEnComponent,
    PrivacyRuComponent,
    SideMenuComponent,
    TermsComponent,
    TermsDeComponent,
    TermsEnComponent,
    TermsRuComponent,
  ],
  entryComponents: [
    OnboardingProfileModalComponent,
    PersonTypeSelectionComponent
  ],
  exports: [],
  providers: [
    AppConfig,
    // Initializer
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
