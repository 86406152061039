import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {PatientOnboardingSubscriptionComponent} from './patient-onboarding/patient-onboarding-subscription/patient-onboarding-subscription.component';
import {FullLayoutComponent} from '../shared/layouts/full/full-layout.component';
import {PatientOnboardingStartComponent} from './patient-onboarding/patient-onboarding-start/patient-onboarding-start.component';
import {PatientOnboardingHealthComponent} from './patient-onboarding/patient-onboarding-health/patient-onboarding-health.component';
import {PatientOnboardingMfaComponent} from './patient-onboarding/patient-onboarding-mfa/patient-onboarding-mfa.component';
import {PatientOnboardingFinishedComponent} from './patient-onboarding/patient-onboarding-finished/patient-onboarding-finished.component';
import {PatientOnboardingPaymentComponent} from './patient-onboarding/patient-onboarding-payment/patient-onboarding-payment.component';
import {SubsPlanResolverService} from '../core/subs-plan/subs-plan-resolver.service';
import {PatientOnboardingPaymentSuccessComponent} from './patient-onboarding/patient-onboarding-payment-success/patient-onboarding-payment-success.component';
import {PatientSettingsProfileComponent} from './patient-settings/patient-settings-profile/patient-settings-profile.component';
import {PaymentSettingsPersonComponent} from './patient-settings/patient-settings-person/payment-settings-person.component';
import {PatientSettingsSubscriptionComponent} from './patient-settings/patient-settings-subscription/patient-settings-subscription.component';
import {PatientRegistrationStartComponent} from './patient-registration/patient-registration-start/patient-registration-start.component';
import {PatientRegistrationConfirmationSentComponent} from './patient-registration/patient-registration-confirmation-sent/patient-registration-confirmation-sent.component';
import {PatientRegistrationSuccessComponent} from './patient-registration/patient-registration-success/patient-registration-success.component';
import {PatientRegistrationConfirmationComponent} from './patient-registration/patient-registration-confirmation/patient-registration-confirmation.component';
import {PatientLoginComponent} from './patient-login/patient-login.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {PatientSettingsPermissionsComponent} from './patient-settings/patient-settings-permissions/patient-settings-permissions.component';
import {PatientSettingsMfaComponent} from './patient-settings/patient-settings-mfa/patient-settings-mfa.component';
import {PatientSettingsPasswordComponent} from './patient-settings/patient-settings-password/patient-settings-password.component';
import {PatientSettingsActivityLogsComponent} from './patient-settings/patient-settings-activity-log/patient-settings-activity-logs.component';
import {PatientSettingsAccountDeletionComponent} from './patient-settings/patient-settings-account-deletion/patient-settings-account-deletion.component';
import {PatientDashboardComponent} from './patient-dashboard/patient-dashboard.component';
import {AllergiesComponent} from './health/allergies/allergies.component';
import {DiseasesComponent} from './health/diseases/diseases.component';
import {FamilyDiseasesComponent} from './health/family-diseases/family-diseases.component';
import {ImmunisationsComponent} from './health/immunisations/immunisations.component';
import {MedicationsComponent} from './health/medications/medications.component';
import {OperationsComponent} from './health/operations/operations.component';
import {BloodGlucoseComponent} from './measurements/blood-glucose/blood-glucose.component';
import {BloodPressureComponent} from './measurements/blood-pressure/blood-pressure.component';
import {BodyTemperatureComponent} from './measurements/body-temperature/body-temperature.component';
import {FeelingComponent} from './measurements/feeling/feeling.component';
import {HeartRateComponent} from './measurements/heart-rate/heart-rate.component';
import {PainComponent} from './measurements/pain/pain.component';
import {SleepComponent} from './measurements/sleep/sleep.component';
import {Spo2Component} from './measurements/spo2/spo2.component';
import {StressComponent} from './measurements/stress/stress.component';
import {WeightComponent} from './measurements/weight/weight.component';
import {PatientMedicsComponent} from './patient-medics/patient-medics.component';
import {DocumentsComponent} from './documents/documents.component';
import {EmergencyCardComponent} from './emergency-card/emergency-card.component';
import {DocumentViewerComponent} from './documents/document-viewer/document-viewer.component';
import {DicomViewerComponent} from './documents/dicom-viewer/dicom-viewer.component';
import {TimelineComponent} from './timeline/timeline.component';
import {PatientRegistrationConfirmationInvalidComponent} from './patient-registration/patient-registration-confirmation-invalid/patient-registration-confirmation-invalid.component';

const patientRoutes: Routes = [
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {path: 'dashboard', component: PatientDashboardComponent},
      // TODO START - Rework this views
      {path: 'documents/viewer', component: DocumentViewerComponent},
      {path: 'documents/dicom-viewer', component: DicomViewerComponent},
      // TODO END
      {path: 'emergencyPasses', component: EmergencyCardComponent},
      {
        // TODO add AuthenticatedPatientGuard
        // TODO rename people to patient
        path: 'people/:id',
        children: [
          {path: 'documents', component: DocumentsComponent},
          {path: 'documents/:documentId/:action', component: DocumentsComponent},
          {path: 'emergency-pass', component: EmergencyCardComponent},
          {
            path: 'health',
            children: [
              {path: '', pathMatch: 'full', redirectTo: 'diseases'},
              {path: 'allergies', component: AllergiesComponent},
              {path: 'allergies/:allergyId/:action', component: AllergiesComponent},
              {path: 'diseases', component: DiseasesComponent},
              {path: 'diseases/:diseaseId/:action', component: DiseasesComponent},
              {path: 'family-diseases', component: FamilyDiseasesComponent},
              {path: 'family-diseases/:familyDiseaseId/:action', component: FamilyDiseasesComponent},
              {path: 'immunisations', component: ImmunisationsComponent},
              {path: 'immunisations/:immunisationId/:action', component: ImmunisationsComponent},
              {path: 'medications', component: MedicationsComponent},
              {path: 'medications/:medicationId/:action', component: MedicationsComponent},
              {path: 'operations', component: OperationsComponent},
              {path: 'operations/:operationId/:action', component: OperationsComponent},
            ]
          },
          {
            path: 'measurements',
            children: [
              {path: '', pathMatch: 'full', redirectTo: 'weight'},
              {path: 'blood-glucose', component: BloodGlucoseComponent},
              {path: 'blood-pressure', component: BloodPressureComponent},
              {path: 'body-temperature', component: BodyTemperatureComponent},
              {path: 'feeling', component: FeelingComponent},
              {path: 'heart-rate', component: HeartRateComponent},
              {path: 'pain', component: PainComponent},
              {path: 'sleep', component: SleepComponent},
              {path: 'spo2', component: Spo2Component},
              {path: 'stress', component: StressComponent},
              {path: 'weight', component: WeightComponent},
            ]
          },
          {path: 'medics', component: PatientMedicsComponent},
          {path: 'medics/:medicId/:action', component: PatientMedicsComponent},
          {path: 'timeline', component: TimelineComponent},
        ]
      },
      {
        // TODO add IsNotLoggedInGuard
        path: 'login',
        children: [
          {path: '', pathMatch: 'full', redirectTo: 'signin'},
          {path: 'change-password/:email', component: ChangePasswordComponent},
          {path: 'forgot-password', component: ForgotPasswordComponent},
          {path: 'forgot-password/:email', component: ForgotPasswordComponent},
          {path: 'reset-password/:email', component: ResetPasswordComponent},
        ]
      },
      {
        // TODO add PatientOnboardingGuard
        path: 'onboarding',
        children: [
          {path: '', pathMatch: 'full', redirectTo: 'start'},
          {path: 'finished', component: PatientOnboardingFinishedComponent},
          {path: 'health', component: PatientOnboardingHealthComponent},
          {path: 'mfa', component: PatientOnboardingMfaComponent},
          {path: 'payment/:subsPlanCode/:renewalPeriod', component: PatientOnboardingPaymentComponent, resolve: {subsPlan: SubsPlanResolverService}},
          {path: 'payment-success', component: PatientOnboardingPaymentSuccessComponent},
          {path: 'start', component: PatientOnboardingStartComponent},
          {path: 'subscription', component: PatientOnboardingSubscriptionComponent},
        ]
      },
      {
        // TODO extract patient to lazy-loading module in app-routing.module
        path: 'patient/settings',
        children: [
          {path: '', pathMatch: 'full', redirectTo: 'person'},
          {path: 'account-deletion', component: PatientSettingsAccountDeletionComponent},
          {path: 'activity-logs', component: PatientSettingsActivityLogsComponent},
          {path: 'mfa', component: PatientSettingsMfaComponent},
          {path: 'password', component: PatientSettingsPasswordComponent},
          {path: 'permissions', component: PatientSettingsPermissionsComponent},
          {path: 'person', component: PaymentSettingsPersonComponent},
          {path: 'profile', component: PatientSettingsProfileComponent},
          {path: 'subscription', component: PatientSettingsSubscriptionComponent},
        ]
      },
      {
        // TODO add IsNotLoggedInGuard
        path: 'registration',
        children: [
          {path: '', pathMatch: 'full', redirectTo: 'start'},
          {path: 'confirmation/:email', component: PatientRegistrationConfirmationComponent},
          {path: 'confirmation-invalid/:email', component: PatientRegistrationConfirmationInvalidComponent},
          {path: 'confirmation-sent/:email', component: PatientRegistrationConfirmationSentComponent},
          {path: 'start', component: PatientRegistrationStartComponent},
          {path: 'success', component: PatientRegistrationSuccessComponent},
        ]
      },
      // TODO add IsNotLoggedInGuard
      {path: 'signin', component: PatientLoginComponent}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(patientRoutes)],
  exports: [RouterModule],
})
export class PatientRoutingModule {}
